import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import p1 from 'assests/Photos/SchoolProgram/2023/Science_Exhibition/1.jpg';
import p2 from 'assests/Photos/SchoolProgram/2023/Science_Exhibition/2.jpg';
import p3 from 'assests/Photos/SchoolProgram/2023/Science_Exhibition/3.jpg';
import p4 from 'assests/Photos/SchoolProgram/2023/Science_Exhibition/4.jpg';
import p5 from 'assests/Photos/SchoolProgram/2023/Science_Exhibition/5.jpg';
import p6 from 'assests/Photos/SchoolProgram/2023/Science_Exhibition/6.jpg';
import p7 from 'assests/Photos/SchoolProgram/2023/Science_Exhibition/7.jpg';
import p8 from 'assests/Photos/SchoolProgram/2023/Science_Exhibition/8.jpg';
import p9 from 'assests/Photos/SchoolProgram/2023/Science_Exhibition/9.jpg';
import p10 from 'assests/Photos/SchoolProgram/2023/Science_Exhibition/10.jpg';
import p11 from 'assests/Photos/SchoolProgram/2023/Science_Exhibition/11.jpg';
import p12 from 'assests/Photos/SchoolProgram/2023/Science_Exhibition/12.jpg';
import p13 from 'assests/Photos/SchoolProgram/2023/Science_Exhibition/13.jpg';
import p14 from 'assests/Photos/SchoolProgram/2023/Science_Exhibition/14.jpg';
import p15 from 'assests/Photos/SchoolProgram/2023/Science_Exhibition/15.jpg';
import p16 from 'assests/Photos/SchoolProgram/2023/Science_Exhibition/16.jpg';
import p17 from 'assests/Photos/SchoolProgram/2023/Science_Exhibition/17.jpg';
import p18 from 'assests/Photos/SchoolProgram/2023/Science_Exhibition/18.jpg';
import p19 from 'assests/Photos/SchoolProgram/2023/Science_Exhibition/19.jpg';
import p20 from 'assests/Photos/SchoolProgram/2023/Science_Exhibition/20.jpg';
import p21 from 'assests/Photos/SchoolProgram/2023/Science_Exhibition/21.jpg';
import p22 from 'assests/Photos/SchoolProgram/2023/Science_Exhibition/22.jpg';
import p23 from 'assests/Photos/SchoolProgram/2023/Science_Exhibition/23.jpg';
import p24 from 'assests/Photos/SchoolProgram/2023/Science_Exhibition/24.jpg';
import p25 from 'assests/Photos/SchoolProgram/2023/Science_Exhibition/25.jpg';
import p26 from 'assests/Photos/SchoolProgram/2023/Science_Exhibition/26.jpg';
import p27 from 'assests/Photos/SchoolProgram/2023/Science_Exhibition/27.jpg';
import p28 from 'assests/Photos/SchoolProgram/2023/Science_Exhibition/28.jpg';
import p29 from 'assests/Photos/SchoolProgram/2023/Science_Exhibition/29.jpg';
import p30 from 'assests/Photos/SchoolProgram/2023/Science_Exhibition/30.jpg';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from 'views/SchoolProgram2023/components';

const ScienceExhibition2023 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        }, 
        {
          src: p4,
          source: p4,
          rows: 1,
          cols: 1,
      },
      {
          src: p5,
          source: p5,
          rows: 1,
          cols: 1,
      },
      {
          src: p6,
          source: p6,
          rows: 1,
          cols: 1,
      }, 
      {
          src: p7,
          source: p7,
          rows: 1,
          cols: 1,
      },
      {
          src: p8,
          source: p8,
          rows: 1,
          cols: 1,
      },
      {
          src: p9,
          source: p9,
          rows: 1,
          cols: 1,
      }, 
      {
          src: p10,
          source: p10,
          rows: 1,
          cols: 1,
      },
      {
          src: p11,
          source: p11,
          rows: 1,
          cols: 1,
      },
      {
          src: p12,
          source: p12,
          rows: 1,
          cols: 1,
      }, 
      {
          src: p13,
          source: p13,
          rows: 1,
          cols: 1,
      },
      {
          src: p14,
          source: p14,
          rows: 1,
          cols: 1,
      },
      {
          src: p15,
          source: p15,
          rows: 1,
          cols: 1,
      }, 
      {
          src: p17,
          source: p17,
          rows: 1,
          cols: 1,
      }, 
      {
          src: p19,
          source: p19,
          rows: 1,
          cols: 1,
      },
      {
          src: p20,
          source: p20,
          rows: 1,
          cols: 1,
      }, 
      {
          src: p22,
          source: p22,
          rows: 1,
          cols: 1,
      },
      {
          src: p23,
          source: p23,
          rows: 1,
          cols: 1,
      },
      {
          src: p24,
          source: p24,
          rows: 1,
          cols: 1,
      }, 
      {
          src: p25,
          source: p25,
          rows: 1,
          cols: 1,
      },
      {
          src: p26,
          source: p26,
          rows: 1,
          cols: 1,
      },
      {
          src: p27,
          source: p27,
          rows: 1,
          cols: 1,
      }, 
      {
          src: p28,
          source: p28,
          rows: 2,
          cols: 1,
      },
      {
          src: p29,
          source: p29,
          rows: 2,
          cols: 1,
      },
      {
          src: p30,
          source: p30,
          rows: 2,
          cols: 1,
      }, 
      {
          src: p16,
          source: p16,
          rows: 2,
          cols: 1,
      },
      {
          src: p18,
          source: p18,
          rows: 2,
          cols: 1,
      },
      {
          src: p21,
          source: p21,
          rows: 2,
          cols: 1,
      },
         
    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                            
                                <Typography variant='h4' align='center'>
                                SCIENCE EXHIBITION
                                </Typography>
                                
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                              Class: 6 to 8            Date: 05 FEBRUARY 2024           
                                </Typography>
                                <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “Knowledge empowers, creativity inspires.”

                                <br />
                            </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                A Science Exhibition- Wonders of Imagination was organised by NPS, Yeshwanthpur with great fervour on Monday 5th February 2024. The event saw young enthusiastic participants of classes 6-8 putting up interactive exhibits of working models and the investigation-based projects.   
                                    <br></br>
                                The exhibition was inaugurated by the Principal, Ms Sheeja Manoj. The event was successful in promoting scientific curiosity, creativity and collaborative learning among the youngsters.  It fostered a deeper appreciation for wonders of Science and the power of imagination. The effort of all the participants was laudable and praiseworthy.
                                </Typography>
                            </Box>
                            {/* <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “Go confidently in the direction of your dreams. Live the life you have imagined.”

                                <br />
                            </Typography> */}

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={3}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <Box marginBottom={4}>
                            <Sidebar2023/>
                        </Box> 
                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default ScienceExhibition2023;